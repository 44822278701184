import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { Container } from './styles';
import { device } from '../../theme';

const settings = {
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 8000,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <FaArrowRight />,
  prevArrow: <FaArrowLeft size={5} />,
};

const ImagesCarousel = ({ slides }) => {
  const mapImageItem = ({ image, mobileImage, title, action }) => {
    const sources = [
      mobileImage.childImageSharp.fluid,
      {
        ...image.childImageSharp.fluid,
        media: device.smallOnly,
      },
    ];
    return (
      <div key={title}>
        <Img fluid={sources} alt={title} loading="eager" />
        {action && (
          <a
            href={action}
            style={{
              bottom: '0px',
              left: '0px',
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          />
        )}
      </div>
    );
  };

  return (
    <Container>
      <Slider {...settings}>{slides.map(mapImageItem)}</Slider>
    </Container>
  );
};

ImagesCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object.isRequired,
      mobileImage: PropTypes.object.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  action: PropTypes.string,
};
ImagesCarousel.defaultProps = {
  action: null,
};
export default ImagesCarousel;
