import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ImagesCarousel from '../../components/ImagesCarousel';

const CarouselHero = () => {
  const carouselData = useStaticQuery(graphql`
    query CarouselHeroQuery {
      allCarouselHeroJson {
        nodes {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          mobileImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          action 
        }
      }
    }
  `);

  const slides = carouselData.allCarouselHeroJson.nodes;

  return <ImagesCarousel slides={slides} />;
};

export default CarouselHero;
